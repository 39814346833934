@import '../../responsive-style/variables.scss';
.nav-bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  @include small-up{
    width: 100%;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    min-height: 0;
  }

  .logo {
    display: block;
    padding: 8px 0;

    @include small-up{
    float: left;
    padding: 2px 0;
    margin-right: 20px;
    }
    img:first-child {
      height: 30px;
  }
    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      @include small-up{
        display: block;
        margin: 5px 15px 5px 19px;
        width: auto;
        height: auto;
      }

      &.sub-logo {
        width: 50px;
        margin-top:-7px;
        @include small-up{
          width: 50px;
          margin-left: 13px;
        }
      }
    }
  }

  .nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;
    @include small-up {
     width: 100%;
    position: fixed;
    left: 100%;
    opacity: 0;
    margin-left: 0;
    float: left;
    overflow: hidden;
    text-align: center;
    height: 60px;
    top: 60px;
    margin-top: 0;
    background: #181818;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    
  }
  
    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;
      @include small-up{
        float: left;
        margin-left: 7% !important;
        line-height: 60px;
        height: 60px;
        width: 16.5%;
      }

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #d4af37;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
      &:after {
        content: 'PORTFOLIO';
      }
    }

    a.active {
      svg {
        color: #d4af37;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;
    @include small-up{
      display: none;
    }

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #4d4d4e;

        &:hover {
          color: #d4af37;
        }
      }
    }
  }
    #mobile-link{
     display:none;
    @include small-up{
      font-size: 30px;
      color: #fff;
      float: right;
      margin: 10px 20px 10px 10px;
      display: block;
    }
   }
  #mobile-link:hover {
    color: #d4af37;
  }

  .nav.show{
    left:0;
    opacity:1;
 }
}