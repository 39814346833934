@import '../../responsive-style/variables.scss';

.container.contact-page {
 @include small-up{
  height:120vh;
 }
 .top-tags {
    bottom: auto;
    top: 10px;
     @include small-up{ 
      top:10%;
     }
 }


 .bottom-tags {
   bottom: 0;
   @include small-up{
   position: absolute;
   clear: both;
   float: left;
   margin-top: 22px;
   

   }
  }
  .tags {
    color: #d4af37;
    opacity: 0.6;
    position: absolute;
    left: 80px;
    font-size: 18px;
    font-family: 'La Belle Aurore', cursive;
 
     @include small-up{
       margin-left: 30px;
       left: 0;
     }
   }
   .contact-title{
    top:15px;
    @include small-up{
      top:-50px
    }
   }
 .contact-form {
  width: 100%;
  margin-top: 20px;
 @include small-up{
  width: 90%;
  margin:0 auto;
  padding: 30px 0;
 }
  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;
      @include small-up{
        width:100%;
        margin-left: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #444;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #444;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: #d4af37;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #d4af37;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
  }
 }

 .map-wrap {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 53%;
  height: 115%;
  margin-top: -3%;
  @include small-up{
    float: none;
    width: 80%;
    margin-left: 10%;
    height: 320px;
    clear: both;
  }
 }

 .leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
 }

 .info-map {
  position: absolute;
  background: #000;
  top: 50px;
  right: 30%;
  z-index: 999999;
  width: 267px;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;
  @include small-up{
    margin-left: 10%;
    width: 80%;
    position:static;
    clear: both;
    top:auto;
    right: 5%;
    bottom:50px;

  }

   span {
    font-size: 16px;

    span {
      color: #d4af37;
    }
   }
  }
}