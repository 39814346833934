*{
  box-sizing: border-box;

}
html{
  font-size: 62.5%;
}


body {
  margin: 0;
  font:300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: #444;
  background: #2b2b2b;
  overflow: hidden;
  display: block;
  /* height: 100%; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}


