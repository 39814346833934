$small-width: 650px;
$medium-width: 990px;
$large-width: 1024px;

@mixin extra-small {
  @media (max-width: #{$small-width - 1px}) {
    @content;
  }
}

@mixin small-down {
  @media (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin small-only {
  @media (min-width: #{$small-width}) and (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin small-up {
  @media (max-width: #{$small-width}) {
    @content;
  }
}

@mixin medium-only {
  @media (min-width: #{$medium-width}) and (max-width: #{$large-width - 1px}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}