@import "../../../responsive-style/variables.scss";

.logo-container {
    z-index: -1;
    width: 400px;
    height: 609px;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
    @include small-up{
     right: 0;
     left: 0;
     width: 263px;
    height: 400px;



    }
    svg {
      width: 81%;
      height: auto;
      bottom: 0;
      transform:translate(-1%,26%) rotateZ(30deg) !important;
    }


    .solid-logo {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      opacity:0;
      transform:translate(-4%,43%) rotateZ(28deg) !important;
      z-index: 1;
      
    }
  }

  .svg-container {
    stroke: #d4af37;
    stroke-width: 10;
  }
  
  