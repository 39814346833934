@import '../../responsive-style/variables.scss';

.page {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: auto;

   @include small-up{
     overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
   }

   .top-tags {
    bottom: auto;
    top: 0;
     @include small-up{
      top:76px;
     }
  }
  .bottom-tags {
     bottom: 0;
     top:auto;
    @include small-up{
     position: absolute;
     clear: both;
     float: left;
     margin-top: 22px;
     bottom: 0;
    }
  }
 
   .tags {
    color: #d4af37;
    opacity: 0.6;
    position: absolute;
    left: 80px;
    font-size: 18px;
    font-family: 'La Belle Aurore', cursive;
 
     @include small-up{
       margin-left: 30px;
       left: 0;
     }
   }
}

 

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
  

  @include small-up{
    width: 100%;
    height: auto;
    padding-bottom: 58px;
    box-sizing: border-box;
    position: relative;
    top: 0;
    

  }
  
     
  
}

.container.contact-page,
.container.about-page,
.container.portfolio-page {
    
  

    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #d4af37;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;
      @include small-up{
        font-size: 47px !important;
        line-height:40px !important;
        margin-top: 30px;
      }

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #d4af37;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
        @include small-up{
          margin-top: -29px;
        }
       
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #d4af37;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
        @include small-up{
          bottom: -40px;
        }
      }
    }

    p {
      font-size: 13px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
    @include small-up{
      width: 80%;
      min-height:0;
      position: static !important;
      float: left;
      margin-left: 10%;
      margin-top: 170px;
      transform: none !important;
    }
}

