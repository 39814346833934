@import'../../responsive-style/variables.scss';

.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    height: 100%;
    width: 100%;
  @include small-up{
    padding: 30px 60px;
  }
  .top-tags {
    bottom: auto;
    top: 0;
     @include small-up{
      
      top:10%;
     }
  }
  .bottom-tags{
    bottom:auto;
    @include small-up{
       
    }
  }
    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
        @include small-up{
            margin-left:0;
            margin-bottom: 60px;
            margin-top:120px;
        }
    }

    .images-container {
        display: flex;
        flex: 1 1  20%;
        gap: 10px;
        flex-wrap: wrap;
        padding-bottom: 100px;
        margin:0 auto;
        animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

        @include small-up{
            flex-direction: column;
            width: 100%;
        }
       
    }

    .image-box {
        position: relative;
        width: 25%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);
        margin-bottom: 20px;
        float: left;  
        @include small-up{
            width: 100%;
            max-width:100%;
            margin-bottom: 20px;
        }

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #d4af37;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #d4af37;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #d4af37, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
    .see-more {
        font-size:15px;
        color:#fff;
        margin: 0 auto;
        text-align: center;
        .arrow{
            padding:0 10px;
            font-size:15px
        }
        a{
            font-size:25px;
            color:#fff;
            padding: 0 10px;
        }
    }
}